import React from "react"

import Title from "./Title"
import { ContentWrapper, Content } from "./Content"
import venuImg from "../images/venue.jpg"

export default () => (
  <>
    <Title image={venuImg}>
      <h2>The Venue</h2>
    </Title>
    <ContentWrapper>
      <Content>
        <p>
          We're tying the knot in a magical location: Elina’s brother’s garden!
          Yep, it’s as fancy as it sounds—flowers, fairy-tale vibes, and a
          sprinkle of sibling generosity. Look out for balloons on the gate
          (we’re classy like that). It’s the second house once you’re through
          the gate—no wrong turns allowed!
        </p>
        <p>
          Getting there is easy:
          <br />
          🚋 Hop on the number 10 tram heading to <i>Bišumuiža</i> and hop off
          at <i>Arkādijas parks</i>. From there, it’s a scenic 10-minute walk.
          Bonus cardio before cake!
          <br />
          🚕 Feeling fancy? A taxi from the city center will set you back no
          more than €10—worth it to arrive in style.
        </p>
        <p>See you there—don’t forget your dancing shoes!</p>
        <a href="https://www.google.com/maps/d/u/0/edit?mid=1hAIwt46uGmEQ87rJARrglejbNV8UCEU&usp=sharing">
          Map
        </a>
      </Content>
      <Content>
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1hAIwt46uGmEQ87rJARrglejbNV8UCEU&ehbc=2E312F"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          title="Map to venue"
        ></iframe>
      </Content>
    </ContentWrapper>
  </>
)
