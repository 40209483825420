import React from "react"
import styled from "styled-components"

import { ContentWrapper, Content } from "./Content"

const Button = styled.a`
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: green;
  color: white;
  text-decoration: none;
`

export default () => (
  <ContentWrapper>
    <Content>
      <p>
        So we are finally trying to get married again! Woo! 🙌 And if you are
        here then you are invited (unless you are a bot 🤖). There is going to
        be good food 🍰, great music 🎶 (we've got a 3 day long spotify
        playlist), bad dancing 💃🏻🕺 (you've met us already) and a few games 🕹️
        (not just for the kids).
      </p>
      <p>
        We'd love to know if you are coming and if you are bringing anyone. So
        hit the button and let us know.
      </p>
      <Button href="mailto:me+wedding@tomandrews.co.uk?subject=RSVP&body=Yo nerds, I'm coming with my bf/gf/wife/husband/offspring/cat">
        RSVP
      </Button>
    </Content>
  </ContentWrapper>
)
