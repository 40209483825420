import React from "react"
import styled from "styled-components"

import headerImg from "../images/header.jpg"
import smallHeaderImg from "../images/header-small.jpg"

import Counter from "./Counter"
import Title from "./Title"

const Names = styled.h1`
  font-size: 6rem;
  margin-bottom: 1rem;
`

const Subtitle = styled.h2`
  font-family: inherit;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`

const WEDDING_DATE = new Date("2025-06-22T17:00:00.000+02:00")

export default () => (
  <Title image={headerImg} smallImage={smallHeaderImg}>
    <Names>Elina & Tom</Names>
    <Subtitle>
      are trying to get married again on the 22nd of June 2025
    </Subtitle>
    <Counter eventDateTime={WEDDING_DATE} />
  </Title>
)
